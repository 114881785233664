var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { staticClass: "my-16", attrs: { justify: "center" } },
        [
          _c("div", { staticStyle: { width: "40px" } }),
          _c("v-spacer"),
          _c("div", { staticClass: "text-h4 d-flex justify-center" }, [
            _vm._v(" Droits utilisateurs "),
          ]),
          _c("v-spacer"),
          _c("div", { staticStyle: { width: "40px" } }),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "mt-6", attrs: { justify: "center" } },
        [
          _c(
            "v-col",
            { attrs: { xl: "6", lg: "8", md: "10", sm: "10", xs: "12" } },
            [
              _c(
                "div",
                { staticClass: "justify-center" },
                [
                  _c(
                    "v-row",
                    { attrs: { justify: "center" } },
                    [
                      _vm.backbutton
                        ? _c(
                            "v-btn",
                            {
                              attrs: { icon: "", large: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.back()
                                },
                              },
                            },
                            [
                              _c("v-icon", { attrs: { color: "primary" } }, [
                                _vm._v("mdi-arrow-left"),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                      !_vm.backbutton
                        ? _c("div", { staticStyle: { width: "40px" } })
                        : _vm._e(),
                      _c("v-spacer"),
                      _c(
                        "div",
                        { staticClass: "text-h6 d-flex justify-center" },
                        [_vm._v(" Désolé ! l'accès a été refusé. ")]
                      ),
                      _c("v-spacer"),
                      _c("div", { staticStyle: { width: "40px" } }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "d-flex justify-center" }, [
                _vm._v(
                  " vos droits d'accès ne vous permettent pas d'afficher la page demandée. "
                ),
              ]),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }