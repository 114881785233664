<template>
  <v-container fluid>
    
    <v-row justify="center" class="my-16">      
      <div style="width: 40px"></div>
      <v-spacer></v-spacer>
      <div class="text-h4 d-flex justify-center">
        Droits utilisateurs
      </div>
      <v-spacer></v-spacer>
      <div style="width: 40px"></div>
    </v-row>


    <v-row justify="center" class="mt-6">
      <v-col xl="6" lg="8" md="10" sm="10" xs="12">
        <!-- le titre et le bouton retour -->
        <div class=" justify-center">
          <v-row justify="center" >
            <v-btn icon large @click="back()" v-if="backbutton">
              <v-icon color="primary">mdi-arrow-left</v-icon>
            </v-btn>
            <div v-if="!backbutton" style="width: 40px"></div>
            <v-spacer></v-spacer>
            <div class="text-h6 d-flex justify-center">
              Désolé ! l'accès a été refusé.
            </div>
            <v-spacer></v-spacer>
            <div style="width: 40px"></div>
          </v-row>
        </div>

        <div class="d-flex justify-center">
          vos droits d'accès ne vous permettent pas d'afficher la page demandée.
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>



<script>
export default {
  name: "PermissionError",
  components: {  },
  mixins: [],

  data() {
    return {
      /**Est-ce que on affiche le bouton 'retour'? */
      backbutton: {
        default: true,
      },

    };
  },
  methods: {
    back() {
      this.$router.go(-1);
    },

  },
  mounted() {    
    

  },

};
</script>

      


